@import '../../variables.scss';

.process-columns {
}

.process-columns-columns {
  @media (min-width: $largebp) {
    display: flex;
  }
}

.process-column {
  flex: 1;

  h2 {
    font-size: 26px;
    font-weight: bold;
    color: #184364;
    text-align: center;
    margin-bottom: 30px;

    @media (min-width: $largebp) {
      margin-bottom: 40px;
      font-size: 36px;
    }
  }

  & + & {
    margin-top: 60px;

    @media (min-width: $largebp) {
      margin: 0 0 0 60px;
    }
  }
}
