@import './variables.scss';

@font-face {
  font-family: 'Eina 02';
  src: url('/fonts/hinted-subset-Eina02-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 02';
  src: url('/fonts/hinted-subset-Eina02-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 02';
  src: url('/fonts/hinted-subset-Eina02-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 02';
  src: url('/fonts/hinted-subset-Eina02-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.65;
  font-family: $font;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: url('/images/bg.jpg');
  background: linear-gradient(180deg, rgb(184, 224, 245) 0%, white 80%);
  background-size: 100% 100vh;
  background-position: top center;
  background-repeat: no-repeat;
  color: $blue;

  @media (min-width: $largebp) {
    background-size: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.2;
  color: $deepBlue;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

input,
select,
button,
textarea {
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  font-family: $font;
  font-size: 16px;
}

input[type='email'],
input[type='text'],
input[type='password'],
input[type='search'] {
  display: block;
  width: 100%;
}

a[href],
button,
label,
input[type='submit'],
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

img,
svg {
  display: block;
  max-width: 100%;
}

.width {
  max-width: 1220px;
  width: 94%;
  margin: 0 auto;
}
