@import '../../variables.scss';

.process-box {
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.11),
    0 1px 1px 0 rgba(24, 67, 100, 0.07);
  background-color: rgba(255, 255, 255, 0.97);
  max-width: 580px;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: $largebp) {
    max-width: 680px;
    margin: 0 auto;
    padding: 35px 40px;
  }

  & + & {
    margin-top: 32px;
  }
}
