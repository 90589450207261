@import '../../variables.scss';

.home-quotes {
  padding: 100px 0;

  h2 {
    font-size: 26px;
    color: $deepBlue;
    margin-bottom: 30px;

    @media (min-width: $largebp) {
      font-size: 40px;
    }
  }
}

.home-quotes-logos {
  display: none;

  @media (min-width: $largebp) {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: center;
  }

  li {
    filter: grayscale(100%);
    transition: 0.2s filter;
    height: 64px;
    display: flex;
    align-items: center;

    &.active,
    &:hover {
      filter: none;
      cursor: pointer;
    }
  }

  li.active {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      top: -45px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.21),
        0 1px 1px 0 rgba(24, 67, 100, 0.07);
    }
  }

  .y {
    width: 64px;
  }

  .toolchain {
    width: 200px;
  }

  .hvmn {
    width: 160px;
  }

  .avc {
    width: 160px;
  }
}
