@import '../../variables.scss';

.sale-featured-section {
  h2 {
    font-size: 28px;
    color: #184364;
    margin-bottom: 25px;

    @media (min-width: $largebp) {
      font-size: 36px;
    }
  }
}

.sale-featured-list {
  display: flex;
  flex-wrap: wrap;
}
