@import '../../variables.scss';

.sale-domain {
  color: #184364;
  font-size: 18px;
  border-top: 1px solid #d8d8d8;
  padding: 20px;
  display: block;
  transition: 0.2s background-color, 0.2s border-color;

  @media (min-width: $mediumbp) {
    display: flex;
    padding: 10px 20px;
    font-size: 16px;
    align-items: center;
  }

  @media (min-width: $largebp) {
    font-size: 19px;
  }

  @media (min-width: $xlargebp) {
    font-size: 21px;
  }

  &.head {
    text-transform: uppercase;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: rgba(24, 67, 100, 0.8);
    border-bottom: 1px solid #d8d8d8;
    display: none;
    cursor: default;

    @media (min-width: $mediumbp) {
      display: flex;
    }
  }

  .interested {
    display: none;
    font-weight: 600;
  }

  &:first-child {
    border-color: transparent;

    @media (min-width: $largebp) {
      border-color: #d8d8d8;
    }
  }

  &:nth-child(2) {
    border-top: none;

    @media (min-width: $largebp) {
      border-top: 1px solid #d8d8d8;
    }
  }

  &:hover:not(.head) {
    border-radius: 5px;
    background-color: #daecf8;
    border-color: transparent;

    .interested {
      display: block;
      color: $brightBlue;
    }

    .price {
      display: none;
    }
    svg {
      margin-left: 6px;
    }
  }

  &:hover:not(.head) + & {
    border-color: transparent;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.sale-domain-domain {
  display: block;
  font-weight: 600;

  @media (min-width: $mediumbp) {
    width: 160px;
  }

  @media (min-width: $largebp) {
    width: 200px;
  }

  .head & {
    font-weight: 700;
  }
}

.sale-domain-description {
  flex: 1;
  display: block;
  margin: 10px 0;

  @media (min-width: $mediumbp) {
    margin: 0 20px;
  }
}

.sale-domain-price {
  display: block;

  @media (min-width: $mediumbp) {
    text-align: right;
    width: 200px;
  }
}
