@import '../../variables.scss';

.header {
  max-width: 92%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  @media (min-width: $largebp) {
    position: relative;
    padding: 24px 0;
  }

  .logo {
    position: relative;
    z-index: 10;
    font-size: 24px;
    margin-top: 3px;
    font-weight: 700;
    text-transform: lowercase;
    transition: 0.2s transform;

    @media (min-width: $largebp) {
      position: absolute;
      top: 50%;
      left: 0;
      font-size: 32px;
      transform: translateY(-50%);
    }

    &:hover {
      transform: translateY(-3px);

      @media (min-width: $largebp) {
        transform: translateY(-55%);
      }
    }
  }

  .logo a {
    color: $deepBlue;
  }
}

.nav {
  position: absolute;
  left: 0;
  top: 0;
  background: $white;
  width: 100%;
  padding: 70px 4% 0;
  box-shadow: 0px 1px 2px 0px $deepBlue10;
  opacity: 0;
  left: -9999em;
  transition: $fadeOff;

  @media (min-width: $largebp) {
    left: 0;
    opacity: 1;
    position: static;
    display: flex;
    padding: 0;
    justify-content: center;
    background: transparent;
    box-shadow: none;
  }

  .active & {
    opacity: 1;
    left: 0;
    transition: $fadeOn;
  }

  li {
    border-top: 1px solid $deepBlue15;

    @media (min-width: $largebp) {
      border: none;
      font-size: 19px;
    }
  }

  li + li {
    @media (min-width: $largebp) {
      margin-left: 60px;
    }
  }

  li.header-for-sale,
  li.header-contact-us {
    @media (min-width: $largebp) {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    a {
      @media (min-width: $largebp) {
        display: inline-flex;
        background-color: $pinkHover;
        color: $white;
        height: 39px;
        padding: 0 20px;
        align-items: center;
        border-radius: 7px;
        box-shadow: 0 1px 3px 0 rgba(5, 58, 97, 0.15), 0 1px 34px 0 rgba(5, 86, 146, 0.13);
        transition: 0.2s transform, 0.2s background-color;
      }

      &:hover {
        @media (min-width: $largebp) {
          transform: translateY(-3px);
          background: $pinkHover;
        }
      }

      &:after {
        @media (min-width: $largebp) {
          display: none;
        }
      }
    }
  }

  li.header-contact-us {
    @media (min-width: $largebp) {
      right: 210px;

      a {
        background-color: $brightBlue;
        color: $white;

        &:hover {
          background-color: $brightBlueHover;
        }
      }
    }
  }

  a {
    color: $deepBlue;
    display: block;
    padding: 15px 0;
    position: relative;
    font-weight: 600;

    &:after {
      @media (min-width: $largebp) {
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: 4px;
        background-color: #184364;
        opacity: 0;
        transition: 0.2s opacity;
      }
    }

    &:hover:after,
    &.active:after{
      opacity: 1;
    }
  }
}

.hamburger {
  width: 22px;
  height: 20px;
  display: block;
  position: relative;
  z-index: 10;

  @media (min-width: $largebp) {
    display: none;
  }

  .bar {
    width: 22px;
    height: 2px;
    position: absolute;
    left: 50%;
    background: $deepBlue;
    display: block;
    transform: translateX(-50%);

    &:nth-of-type(1) {
      top: 2px;
      opacity: 1;
      transition: 0.2s transform, 0.2s top, 0.2s opacity;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.2s transform;
    }

    &:nth-of-type(4) {
      bottom: 2px;
      opacity: 1;
      transition: 0.2s transform, 0.2s bottom, 0.2s opacity;
    }
  }
}

.active .hamburger {
  .bar:nth-of-type(1) {
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  .bar:nth-of-type(2) {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .bar:nth-of-type(3) {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .bar:nth-of-type(4) {
    bottom: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
  }
}
