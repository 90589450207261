// Colors
$white: #fff;
$black: #000;
$deepBlue: #184364;
$deepBlue10: rgba(24, 67, 100, 0.1);
$deepBlue15: rgba(24, 67, 100, 0.15);
$deepBlue20: rgba(24, 67, 100, 0.2);
$deepBlue30: rgba(24, 67, 100, 0.3);
$deepBlue40: rgba(24, 67, 100, 0.4);
$deepBlue50: rgba(24, 67, 100, 0.5);
$pink: #b342da;
$pinkHover: #9f31c4;

$blue: #2c6188;
$brightBlue: #0c7dd2;
$brightBlueHover: #0c72c0;

// fonts
$font: 'Eina 02', sans-serif;

// Breakpoints
$tinybp: 374px;
$smallbp: 500px;
$smbp: 640px;
$mediumbp: 800px;
$largebp: 1000px;
$xlargebp: 1140px;

// Transitions
$fadeOff: left 0s 0.15s, opacity 0.15s;
$fadeOn: left 0s, opacity 0.15s;
