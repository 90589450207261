@import '../../variables.scss';

.home-person {
  display: flex;

  .image {
    width: 100px;

    @media (min-width: $largebp) {
      width: 128px;
    }

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      box-shadow: 0 2px 14px 0 rgba(4, 30, 47, 0.08);

      @media (min-width: $largebp) {
        width: 128px;
        height: 128px;
      }
    }
  }

  .text {
    margin-left: 30px;
    flex: 1;
  }

  h2 {
    font-size: 20px;
    line-height: 1.58;
    color: $deepBlue;

    @media (min-width: $largebp) {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    color: $blue;
    margin: 8px 0;

    // @media (min-width: $largebp) {
    //   font-size: 20px;
    // }
  }

  a {
    display: inline-flex;
    align-items: center;
    color: #0c7dd2;
    font-size: 16px;
    font-weight: bold;
    transition: 0.2s color, 0.2s transform;

    &:hover {
      color: $brightBlueHover;
      transform: translateY(-2px);
    }

    @media (min-width: $largebp) {
      font-size: 18px;
    }
  }

  svg {
    margin-right: 10px;
  }
}
