@import '../../variables.scss';

.home-top {
  padding: 30px 0 60px;

  @media (min-width: $largebp) {
    padding: 100px 0 100px;
  }

  h1 {
    font-size: 32px;
    text-align: center;
    color: $deepBlue;
    line-height: 1.21;
    max-width: 1000px;
    margin: 0 auto;

    @media (min-width: $largebp) {
      font-size: 48px;
    }
  }
}

.home-top-people {
  //max-width: 1000px;
  margin: 50px auto 0;

  @media (min-width: $mediumbp) {
    display: flex;
    justify-content: space-around;
    margin: 100px auto 0;
  }

  .home-person {
    margin: 0 auto;
    max-width: 400px;

    .person-location {
      font-weight: 700;
      color: #35678d;
      text-transform: uppercase;
      margin-top: 10px;
    }

    @media (min-width: $mediumbp) {
      flex: 1;
      max-width: 50%;
    }
  }

  .home-person + .home-person {
    margin-top: 40px;

    @media (min-width: $mediumbp) {
      margin: 0 0 0 40px;
    }

    @media (min-width: $largebp) {
      margin: 0 0 0 80px;
    }
  }
}
