@import '../../variables.scss';

.home-touch-box {
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.21),
    0 1px 1px 0 rgba(24, 67, 100, 0.07);
  background-color: rgba(255, 255, 255, 0.5);
}

.home-touch-header {
  background-color: #f9fafb;
  height: 100px;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0 25px;

  @media (min-width: $largebp) {
    height: 140px;
    padding: 0 60px;
  }

  h2 {
    font-size: 26px;
    color: $deepBlue;

    @media (min-width: $largebp) {
      font-size: 40px;
    }
  }
}

.home-touch-body {
  padding: 30px 25px;

  @media (min-width: $largebp) {
    padding: 30px 60px 60px;
  }

  p {
    color: $blue;
    font-size: 18px;

    // @media (min-width: $largebp) {
    //   font-size: 20px;
    // }

    &.big {
      font-size: 20px;
      max-width: 780px;

      // @media (min-width: $largebp) {
      //   font-size: 24px;
      // }
    }
  }

  .big + .big {
    margin-top: 25px;
  }
}

.home-touch-specialize {
  li {
    line-height: 2.5em;
    position: relative;
    padding-left: 32px;
    font-size: 18px;

    &:before {
      content: " ";
      display: block;
      height: 16px;
      width: 16px;
      background-image: url(/images/tick.svg);
      background-size: 100%;
      position: absolute;
      top: 15px;
      left: 0;
    }
  }
}

.home-touch-list {
  margin-top: 40px;

  @media (min-width: $largebp) {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  li {
    max-width: 300px;

    svg {
      min-height: 42px;
    }
  }

  li + li {
    margin-top: 40px;

    @media (min-width: $largebp) {
      margin: 0 0 0 40px;
    }

    @media (min-width: $xlargebp) {
      margin: 0 0 0 100px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #184364;
    margin: 15px 0 10px 0;

    @media (min-width: $xlargebp) {
      font-size: 24px;
    }
  }
}

.home-touch-footer {
  margin-top: 60px;

  @media (min-width: $mediumbp) {
    display: flex;
  }

  @media (min-width: $largebp) {
    width: auto;
    justify-content: center;
  }

  .button {
    width: 100%;
    justify-content: center;

    @media (min-width: $mediumbp) {
      width: auto;
    }
  }

  .button + .button {
    margin-top: 20px;

    @media (min-width: $mediumbp) {
      margin: 0 0 0 24px;
    }
  }

  .button svg {
    margin-left: 24px;
  }
}
