@import '../../variables.scss';

.home-quote {
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.21),
    0 1px 1px 0 rgba(24, 67, 100, 0.07);
  background-color: #fff;
  padding: 40px 25px;
  //height: 250px;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    @media (min-width: $largebp) {
      content: '';
      height: 40px;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 2;
      position: absolute;
      background: white;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  @media (min-width: $largebp) {
    padding: 80px 25px;
  }

  .home-quote-inner {
    max-width: 90%;
    margin: 0 auto;
    display: block;

    @media (min-width: $mediumbp) {
      display: flex;
      align-items: center;
    }
  }

  blockquote {
    font-size: 18px;
    color: $blue;
    line-height: 1.6;
    margin-bottom: 20px;

    @media (min-width: $mediumbp) {
      margin-bottom: 0;
    }

    img {
      display: inline-block;
      vertical-align: middle;
    }

    img:first-of-type {
      margin-right: 10px;
    }

    img:last-of-type {
      margin-left: 10px;
    }
  }

  figcaption {
    display: flex;

    @media (min-width: $mediumbp) {
      margin-left: 60px;
      min-width: 300px;
    }

    @media (min-width: $largebp) {
      margin-left: 100px;
    }
  }

  .image {
    display: block;
    width: 45px;
    margin-right: 20px;
  }

  .image img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
  }

  figcaption .text {
    flex: 1;
    display: block;
  }

  figcaption h3 {
    font-size: 20px;
    color: #184364;

    @media (min-width: $largebp) {
      font-size: 22px;
    }
  }

  figcaption p {
    opacity: 0.6;
    color: #184364;
    font-size: 14px;

    a {
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
