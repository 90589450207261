@import '../../variables.scss';

.unlock {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    background: -webkit-linear-gradient(#d2ebf4, #b9e0f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-right: 20px;
    font-size: 73px;
    color: #184364;

    @media (min-width: $mediumbp) {
      font-size: 138px;
      padding-right: 37px;
    }
  }

  input {
    background: white;
    opacity: 0.8;
    box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.11),
      0 1px 1px 0 rgba(24, 67, 100, 0.07);
    background-color: rgba(255, 255, 255, 0.97);
    display: block;
    margin: 0 auto;
    width: 320px;
    font-size: 32px;
    line-height: 60px;
    letter-spacing: 26px;
    padding-left: 20px;

    @media (min-width: $mediumbp) {
      width: 613px;
      font-size: 37px;
      line-height: 80px;
      letter-spacing: 15px;
      padding-left: 40px;
    }
  }
}
