@import '../../variables.scss';

.sale-featured {
  background: $white;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.21),
    0 1px 1px 0 rgba(24, 67, 100, 0.07);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  height: 247px;
  width: calc(50% - 10px);
  margin-bottom: 20px;
  transition: 0.2s transform;
  overflow: hidden;

  &:hover {
    transform: scale(1.03);
  }

  @media (min-width: $largebp) {
    width: calc(25% - 18px);
    margin-bottom: 24px;
  }

  &:nth-of-type(even) {
    margin-left: 20px;

    @media (min-width: $largebp) {
      margin-left: 0;
    }
  }

  &:nth-of-type(4n + 2),
  &:nth-of-type(4n + 3),
  &:nth-of-type(4n + 4) {
    @media (min-width: $largebp) {
      margin-left: 24px;
    }
  }

  h3 {
    font-size: 16px;
    color: rgba(24, 67, 100, 0.8);
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    @media (min-width: $largebp) {
      font-size: 21px;
    }
  }

  h4 {
    font-size: 16px;
    color: rgba(24, 67, 100, 0.5);
    font-weight: 400;

    @media (min-width: $largebp) {
      font-size: 21px;
    }
  }

  span {
    padding: 20px;
  }

  img {
    width: 100%;
    object-fit: cover;
    min-height: 0;
  }
}
