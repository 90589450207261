@import '../../variables.scss';

.process-transparent {
  margin: 80px 0;

  @media (min-width: $largebp) {
    margin: 100px 0;
  }
}

.process-transparent-header {
  text-align: center;
  max-width: 586px;
  margin: 0 auto;

  @media (min-width: $largebp) {
    margin: 0 auto;
  }

  h2 {
    font-size: 26px;
    margin-bottom: 10px;

    @media (min-width: $largebp) {
      font-size: 36px;
    }
  }

  p {
    font-size: 20px;
    color: #2c6188;

    @media (min-width: $largebp) {
      font-size: 24px;
    }
  }
}

.process-transparent-card {
  max-width: 440px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.11),
    0 1px 1px 0 rgba(24, 67, 100, 0.07);
  background-color: rgba(255, 255, 255, 0.97);
  margin: 0 auto;
  padding: 25px 20px;
  margin-top: 70px;

  @media (min-width: $largebp) {
    padding: 25px 40px 40px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: #184364;
    margin-bottom: 8px;

    @media (min-width: $largebp) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 28px;
    font-weight: 300;
    color: #2c6188;
    border-bottom: 1px solid rgba(216, 216, 216, 0.3);
    margin-bottom: 15px;
    padding-bottom: 15px;

    @media (min-width: $largebp) {
      font-size: 33px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.9;
    color: #2c6188;

    @media (min-width: $largebp) {
      font-size: 20px;
    }
  }

  strong {
    color: $deepBlue;
  }

  .button {
    margin-top: 20px;
  }

  .button svg {
    margin-left: 30px;
  }
}
