@import '../../variables.scss';

.sale-top {
  text-align: center;
  padding: 60px 0;

  @media (min-width: $largebp) {
    padding-top: 100px 0;
  }

  h1 {
    font-size: 32px;

    @media (min-width: $largebp) {
      font-size: 48px;
    }
  }

  p {
    font-size: 20px;
    max-width: 880px;
    margin: 20px auto 0;

    @media (min-width: $largebp) {
      font-size: 22px;
    }
  }
}
