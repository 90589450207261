@import '../../variables.scss';

.sale-domains-section {
  margin: 80px 0;

  @media (min-width: $largebp) {
    margin-top: 120px;
  }

  h2 {
    font-size: 28px;
    color: #184364;
    margin-bottom: 25px;

    @media (min-width: $largebp) {
      font-size: 36px;
    }
  }
}

.sale-domains-list {
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(24, 67, 100, 0.12), 0 1px 34px 0 rgba(24, 67, 100, 0.11),
    0 1px 1px 0 rgba(24, 67, 100, 0.07);
  background-color: rgba(255, 255, 255, 0.97);
  padding: 20px;

  @media (min-width: $largebp) {
    padding: 35px 45px;
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(1turn);
  }
}

.loader {
  text-align: center;
  position: relative;
  top: -40px;
  display: flex;
  justify-content: center;
  text-indent: -9999px;

  &:after {
    content: " ";
    display: block;
    top: 0;
    width: 20px;
    height: 20px;
    border: 4px solid currentColor;
    border-radius: 50%;
    border-right-color: transparent;
    animation: spin .7s linear infinite;
  }
}
