@import '../../variables.scss';

.button {
  display: inline-flex;
  background-color: $brightBlue;
  color: $white;
  height: 55px;
  padding: 0 25px;
  align-items: center;
  border-radius: 7px;
  box-shadow: 0 1px 2px 0 rgba(5, 58, 97, 0.23), 0 1px 34px 0 rgba(5, 86, 146, 0.13);
  transition: 0.2s transform;
  font-size: 17px;
  font-weight: 600;
  transition: 0.2s background-color, 0.2s transform;

  @media (min-width: $largebp) {
    font-size: 20px;
  }

  &:hover {
    background-color: $brightBlueHover;
    transform: translateY(-3px);
  }

  &.purple-secondary {
    box-shadow: 0 1px 3px 0 rgba(5, 58, 97, 0.15), 0 1px 34px 0 rgba(5, 86, 146, 0.13);
    background-color: rgba(180, 66, 218, 0.1);
    color: #b342da;

    &:hover {
      background-color: rgba(180, 66, 218, 0.2);
    }
  }

  svg {
    margin-top: 2px;
  }
}
