@import '../../variables.scss';

.process-box-row {
  display: flex;

  &.main {
    margin-bottom: 20px;
  }

  & + & {
    margin-top: 10px;
  }

  .icon {
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
  }

  .text {
    display: block;
    flex: 1;
    margin-left: 20px;
    font-size: 16px;

    @media (min-width: $largebp) {
      font-size: 20px;
    }
  }

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0 1px 34px 0 rgba(24, 67, 100, 0.11), 0 1px 1px 0 rgba(24, 67, 100, 0.07);
    background-color: #cce4f5;
    color: rgba(12, 124, 210, 0.9);
    font-size: 24px;
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
    line-height: 1.6;

    @media (min-width: $largebp) {
      font-size: 22px;
    }
  }

  .bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background-color: #b9c6d0;
  }

  .paper-svg {
    margin-top: 8px;
  }
}
